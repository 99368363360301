import React from "react";
import { colors } from "../../theme";
import { Logo as defaultLogoSrc } from "../../assets/svg";

import {
  StyledHeader,
  LogoWrapper,
  Logo,
  Content,
  ToggleWrapper,
} from "./styled-components";
import { Text, Toggle } from "../../components";
import { ETextVariant, IToggleProps } from "../../types";

interface Props {
  children: React.ReactNode;
  color?: string;
  toggle?: IToggleProps;
  logo?: string;
}

const Header: React.FC<Props> = ({
  children,
  color = colors.red,
  toggle = null,
  logo = defaultLogoSrc,
}) => {
  return (
    <StyledHeader>
      <LogoWrapper
        href="https://www.cncef.org/adherer/"
        target="_blank"
        rel="noopener noreferrer"
        color={color}
      >
        <Logo src={logo} />
      </LogoWrapper>
      <Content>
        <Text variant={ETextVariant.Title1}>{children}</Text>
        {toggle ? (
          <ToggleWrapper>
            <Toggle {...toggle} />
          </ToggleWrapper>
        ) : null}
      </Content>
    </StyledHeader>
  );
};

export default Header;
