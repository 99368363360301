import styled, { css } from "styled-components";
import { breakpoints, colors, fonts } from "../../theme";

export const StyledFooter = styled.div`
  margin-top: 5rem;
  padding: 2.4rem 0;
  background-color: ${colors.greyLightest};
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${breakpoints.max.md} {
    flex-direction: column;
  }
`;

export const LogoWrapper = styled.a`
  display: block;
  position: relative;
  width: 16rem;
  flex: 0 0 16rem;

  ${breakpoints.max.md} {
    flex: 0 0 auto;
    margin-bottom: 2.4rem;
  }
`;
export const Logo = styled.img``;

export const Menu = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 16rem;

  ${breakpoints.max.md} {
    padding-right: 0;
  }
`;

const menuItemStyles = () => css`
  font-weight: 500;
  font-size: ${fonts.size.s};
  color: ${colors.primary};
  text-decoration: none;
  margin: 0 2rem;
`;
export const MenuItem = styled.span`
  ${menuItemStyles}
`;
export const MenuLinkItem = styled.a`
  ${menuItemStyles}

  &:hover {
    text-decoration: underline;
  }
`;
