import React from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { Box, Text, Header, Button, Container } from "../components";
import colors from "../theme/colors";
import { EContainerVariant, ETextVariant } from "../types";

const Home = () => {
  return (
    <div>
      <Header color={colors.primary}>Formulaires d'adhésion</Header>
      <br />
      <br />
      <Text center variant={ETextVariant.Title1}>
        Formulaires d'adhésion
      </Text>
      <Container variant={EContainerVariant.Small}>
        <Box jc="space-between">
          <Link to="/france">
            <Button mt>France</Button>
          </Link>
          <Link to="/patrimoine">
            <Button mt>Patrimoine</Button>
          </Link>
          <Link to="/assurance">
            <Button mt>Assurance</Button>
          </Link>
          <Link to="/credit">
            <Button mt>Crédit</Button>
          </Link>
          <Link to="/immobilier">
            <Button mt>Immobilier</Button>
          </Link>
          <Link to="/cncef">
            <Button mt>CNCEF</Button>
          </Link>
        </Box>
      </Container>
      <br />
      <br />
      <Text center variant={ETextVariant.Title1}>
        Formulaires de renouvellement
      </Text>
      <Container variant={EContainerVariant.Small}>
        <Box jc="space-between">
          <Link to="/credit-renewal">
            <Button mt>Crédit</Button>
          </Link>
          <Link to="/assurance-renewal">
            <Button mt>Assurance</Button>
          </Link>
          <Link to="/assurance-credit-renewal">
            <Button mt>Assurance & Crédit</Button>
          </Link>
        </Box>
      </Container>
    </div>
  );
};

Home.propTypes = {};

export default Home;
