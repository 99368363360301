import React, { useState } from "react";
import { Step, Wizard } from "../containers";
import FormHelper from "../helpers/FormHelper";
import StepHelper from "../helpers/StepHelper";
import { Header, Footer } from "../components";
import { IFormSchema } from "../types";
import useConfirmationModal from "../hooks/useConfirmationModal";
import useThemeContext from "../hooks/useThemeContext";
import api from "../config/api";

interface Props {
  schema: IFormSchema;
}

const FormPageTemplate: React.FC<Props> = ({ schema }) => {
  const { setConfirmationModalProps } = useConfirmationModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const {
    isAccessibilityModeActive,
    switchAccessibilityMode,
  } = useThemeContext();
  const formHelper = new FormHelper(schema);

  const handleSubmit = (isFinalStep: boolean, step: number = 1) => async (
    entryId: number,
    values: any,
    bag: any
  ) => {
    setIsLoading(true);
    bag.setTouched({});

    return new Promise<void | boolean>(async (resolve) => {
      api
        .updateEntry(entryId, {
          completed: isFinalStep,
          values: JSON.stringify(values),
          schema: JSON.stringify(schema), // la config du formulaire
          step,
        })
        .then((res) => {
          const error = !res?.data?.id;
          if (error) {
            handleError();
            resolve(false);
          } else if (isFinalStep) {
            setIsCompleted(true);
          }

          resolve(!error);
        })
        .catch(() => {
          handleError();
          resolve(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleError = () => {
    setConfirmationModalProps({
      isOpen: true,
      title: "Une erreur est survenue",
      description:
        "Vos données n'ont pas pu être sauvegardées. Si le problème persiste, merci de nous contacter.",
      logout: true,
      button: "Fermer",
      error: true,
    });
  };

  return (
    <>
      <Header
        color={formHelper.color}
        logo={formHelper.logo}
        toggle={{
          label: "Accessibilité",
          active: isAccessibilityModeActive,
          handleChange: switchAccessibilityMode,
        }}
      >
        {formHelper.title}
      </Header>
      <Wizard
        completed={isCompleted}
        isLoading={isLoading}
        formHelper={formHelper}
        onSubmit={handleSubmit(true, formHelper.steps.length)}
      >
        {formHelper.steps.map((step, index) => {
          const stepHelper = new StepHelper(step);
          return (
            <Step
              validationSchema={stepHelper.validationSchema}
              step={step}
              key={index}
              onSubmit={handleSubmit(false, index + 1)}
            />
          );
        })}
      </Wizard>

      <Footer
        logo={formHelper.footerLogo}
        links={[
          ...(formHelper.footerCertif
            ? [
                {
                  title: formHelper.footerCertif,
                },
              ]
            : []),
          {
            url: "https://www.cncef.org/mentions-legales/",
            title: "Mentions légales",
          },
          {
            url: "https://www.​cncef.​org/contact/",
            title: "Contact",
          },
        ]}
      />
    </>
  );
};

export default FormPageTemplate;
